import "./App.css";
import {
  Col,
  Result,
  Modal,
  Row,
  Typography,
  Upload,
  Form,
  Button,
  Alert,
  Select,
} from "antd";
import axios from "axios";
import {
  LoadingOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { message } from "antd/lib";
import React, { useState } from "react";
const upload_files = {
  Input: [
    {
      title: "PA - Monthly",
      ext: "xlsx",
      name: "input",
    },
  ],
  Razorpay: [
    {
      title: "UPI Metrics Reporting",
      ext: "xlsx",
      name: "razorpay_upi",
    },
    {
      title: "NPCI Rupay Card Metric",
      ext: "xlsx",
      name: "razorpay_rupay",
    },
    {
      title: "Market Share details",
      ext: "xlsx",

      name: "razorpay_rms",
    },
    {
      title: "RuPay_Last_Month_Metrics (DocX)",
      ext: "docx",
      name: "razorpay_docx",
    },
    {
      title: "Merchant Acquiring Report",
      ext: "xlsx",
      name: "razorpay_merchant_report",
    },
    {
      title: "NPCI TID Summary",
      ext: "xlsx",
      name: "razorpay_tid",
    },
  ],
  MSwipe: [
    {
      title: "MSWIPE UPI data (Convert from xlb to xlsx)",
      ext: "xlsx",
      name: "mswipe_upi",
    },
    {
      title: "NPCIRUPAY_Report (CSV)",
      ext: "csv",
      name: "mswipe_rupay",
    },
  ],
  "CC Avenue": [
    {
      title: "monthly_upi_report",
      ext: "xlsx",
      name: "ccavenue_upi",
    },
    {
      title: "rupay_monthly_report",
      ext: "xlsx",
      name: "ccavenue_rupay",
    },
  ],
  CamsPay: [
    {
      title: "NPCI Data summary",
      ext: "xlsx",
      name: "camspay_npci",
    },
    {
      title: "CAMSPay_RuPay (DocX)",
      ext: "docx",
      name: "camspay_rupay",
    },
  ],
  BillDesk: [
    {
      title: "Annexure 1 _BD UPI",
      ext: "xlsx",
      name: "billdesk_upi",
    },
    {
      title: "BD RuPay (Convert from PDF to DOCX)",
      ext: "docx",
      name: "billdesk_rupay",
    },
  ],
  CashFree: [
    {
      title: "CF__NPCI report",
      ext: "xlsx",
      name: "cashfree_upi",
    },
  ],
  PayU: [
    {
      title: "Annexure 2 _ UPI",
      ext: "xlsx",
      name: "payu_upi",
    },
    {
      title: "NPCI_Circular_Aggregator (DocX)",
      ext: "docx",
      name: "payu_rupay",
    },
  ],
  AmazonPay: [
    {
      title: "Aggregator - Amazon Pay",
      ext: "xlsx",
      name: "amazonpay_upi",
    },
  ],
  Phonepe: [
    {
      title: "PHONEPE NPCI UPI",
      ext: "xlsx",
      name: "phonepe_upi",
    },
  ],
  PineLabs: [
    {
      title: "PINELABS_NPCI_Monthly",
      ext: "xlsx",
      name: "pine_upi",
    },
  ],
};
const LOCAL = process.env.NODE_ENV === "development";

const uploader = LOCAL
  ? "http://127.0.0.1:5001/finetech-prod/us-central1/upload_npci_data"
  : "https://upload-npci-data-vxsw4gxjzq-uc.a.run.app";

const processsor = LOCAL
  ? "http://127.0.0.1:5001/finetech-prod/us-central1/process_npci_data"
  : "https://process-npci-data-vxsw4gxjzq-uc.a.run.app";

const beforeUpload = (file, ext, pattern) => {
  let ext_mime = {
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    csv: "text/csv",
    pdf: "application/pdf",
  };
  if (file.type !== ext_mime[ext]) {
    message.error(`Extension is wrong for ${file.name} -> ${pattern}`);
    return false;
  }
  let p = pattern.split("(")[0].trim();
  if (!file.name.includes(p)) {
    message.error(
      `File name is wrong for ${pattern}, should contain ${p} in ${file.name}`,
    );
    return false;
  }
  return true;
};

function App() {
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [tempFile, setTempFile] = useState(null);

  const uploadButton = (
    <Button
      type="default"
      icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
    >
      Upload
    </Button>
  );

  const FileUpload = (props) => {
    const [loading, setLoading] = useState(false);
    return (
      <Upload
        {...props}
        loading={loading}
        maxFiles={1}
        customRequest={async (options) => {
          const { file, onSuccess, onError } = options;
          const formData = new FormData();
          formData.append("file", file);
          setLoading(true);
          try {
            const response = await axios.post(uploader, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            message.success("Upload successful!");
            onSuccess(response.data);
          } catch (error) {
            message.error("Upload failed.");
            onError(error);
          }
          setLoading(false);
        }}
        beforeUpload={(file) =>
          beforeUpload(file, props.ext, props.pattern) || Upload.LIST_IGNORE
        }
        onChange={(e) => {
          console.log(e);
          if (e.file.status === "done") {
            props.onChange(e);
          }
        }}
      >
        {uploadButton}
      </Upload>
    );
  };
  const normFile = (e) => {
    if (e?.fileList?.length > 0 && e.fileList[0]?.status === "done") {
      return e?.fileList[0]?.response?.url;
    }
  };
  const options = [];
  for (let i = 0; i < 26; i++) {
    options.push({
      value: String.fromCharCode(65 + i),
      label: String.fromCharCode(65 + i),
    });
  }

  return (
    <div className="App">
      <Row className={"container"}>
        <Typography.Title>Welcome to NPCI MIS tool</Typography.Title>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: "1.5rem",
        }}
      >
        Free File Uploader
        <Upload
          loading={loading}
          maxFiles={1}
          customRequest={async (options) => {
            const { file, onSuccess, onError } = options;
            const formData = new FormData();
            formData.append("file", file);
            setLoading(true);
            try {
              const response = await axios.post(uploader, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              message.success("Upload successful!");
              onSuccess(response.data);
            } catch (error) {
              message.error("Upload failed.");
              onError(error);
            }
            setLoading(false);
          }}
          onChange={(e) => {
            console.log(e);
            if (e.file.status === "done") {
              setTempFile(e.file.response.url);
            }
          }}
        >
          {uploadButton}
        </Upload>
      </Row>

      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Result
          status="success"
          title="Successfully Filled all values"
          extra={[
            <Button
              type="primary"
              key="console"
              icon={<DownloadOutlined />}
              onClick={() => {
                let d = +new Date();
                window.open(output.final + `?d=${d}`, "_blank");
              }}
            >
              Download MIS Report
            </Button>,

            output.check_these ? (
              <div
                style={{
                  padding: "10px",
                  margin: "10px",
                  color: "black",
                }}
              >
                Please Check these if there are any errors in:
                <br />
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  {output.check_these.join(", ")}
                </span>
              </div>
            ) : null,
          ]}
        />
      </Modal>
      <Form
        loading={loading}
        className={"formContainer"}
        onFinish={async (d) => {
          setLoading(true);
          try {
            const response = await axios.post(processsor, { ...d });
            setOutput(response.data);
            setShowModal(true);
            message.success("Processing successful!");
          } catch (error) {
            console.log({ error });
            message.error("Processing failed.");
          }
          setLoading(false);
        }}
      >
        <Col span={8}>
          <Form.Item name="new_column" label="Select Column to fill" required>
            <Select placeholder="Select Column to fill" options={options} />
          </Form.Item>
        </Col>
        {Object.entries(upload_files).map(([category, files]) => (
          <div key={category} style={{ marginBottom: 24 }}>
            <div
              style={{
                background: "#e6f4ff",
                padding: "2px 16px 28px 16px",
                marginBottom: 28,
                borderRadius: "8px",
              }}
            >
              <Typography.Title level={2} strong>
                {category}
              </Typography.Title>
              <Row gutter={[16, 16]}>
                {Object.entries(files).map(([fileKey, fileConfig]) => (
                  <Col span={12} key={fileKey}>
                    <Form.Item
                      getValueFromEvent={normFile}
                      valuePropName="fileUrl"
                      label={fileConfig.title}
                      name={[fileConfig.name]}
                      rules={[{ required: false }]}
                    >
                      <FileUpload
                        name={fileConfig.name}
                        pattern={fileConfig.title}
                        ext={fileConfig.ext}
                        acceptedFileType={fileConfig.type}
                        category={fileConfig.category}
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default App;
